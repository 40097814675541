
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import { Sleep } from "../../middlewares/common";
import moment from "moment";
export default class UserInfo extends Vue {
  @Action("setSignOut") private setSignOut: any;
  private userForm: any = {
    username: "",
    realname: "",
    tel: "",
    deptName: "",
    orgName: ""
  };
  public created() {
    this.userForm = {
      username: this.$store.state.userInfo.username,
      realname: this.$store.state.userInfo.realname,
      tel: this.$store.state.userInfo.tel,
      deptName: this.$store.state.userInfo.depts
        .map((p: any) => (p && p.name ? p.name : ""))
        .join("、"),
      orgName: this.$store.state.userInfo.orgName
    };
  }
  private signOut() {
    this.$dialog
      .confirm({
        title: "确认",
        message: "确认退出？"
      })
      .then(async () => {
        this.setSignOut();
        await Sleep(100);
        this.$router.replace("/userlogin");
      })
      .catch(() => {
        console.log("cancel sing out");
      });
  }
}
