import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userinfo" }
const _hoisted_2 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_divider = _resolveComponent("van-divider")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("用户信息")
      ]),
      _: 1
    }),
    _createVNode(_component_van_cell_group, { title: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, {
          modelValue: _ctx.userForm.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userForm.username) = $event)),
          label: "登录账号",
          readonly: "",
          "input-align": "right"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.userForm.realname,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userForm.realname) = $event)),
          label: "姓名",
          readonly: "",
          "input-align": "right"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.userForm.tel,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userForm.tel) = $event)),
          label: "手机号",
          readonly: "",
          "input-align": "right"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.userForm.deptName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userForm.deptName) = $event)),
          label: "所属部门",
          readonly: "",
          "input-align": "right",
          type: "textarea",
          autosize: "",
          rows: "1"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.userForm.orgName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userForm.orgName) = $event)),
          label: "所属机构",
          readonly: "",
          "input-align": "right"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_button, {
        round: "",
        block: "",
        plain: "",
        icon: "replay",
        onClick: _ctx.signOut
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 退出 ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}